import { useState, useContext, useEffect } from "react";
import AuthContext from "../contexts/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";

import axios from "../api/axios";

function Signup() {
  const location = useLocation();
  const { auth, replaceAuth } = useContext(AuthContext);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) {
      navigate("/");
    }
  }, [auth, navigate]);

  async function onSubmit(e) {
    console.log(e);
    e.preventDefault();
    setError("");

    const email = e.target.email.value.trim();
    const password = e.target.password.value;

    // Befülle unnötige erforderliche Felder mit Platzhalter
    const signupData = {
      email: email,
      password: password,
      firstname: "-",
      lastname: "-",
      street: "-",
      postcode: "-",
      city: "-",
      country: "-",
      phone: "-",
    };

    await axios
      .post("/signup", signupData, { withCredentials: true })
      .then((res) => {
        replaceAuth(signupData);
        navigate("/" + (location.state ? location.state : ""));
      })
      .catch((err) => {
        if (err.request) {
          setError(
            "Verbindungsfehler. Überprüfe die Internetverbindung oder versuche es später erneut."
          );
        } else if (err.request.status === 401) {
          setError("Dieser Benutzer existiert bereits.");
        } else {
          setError(err.toString());
        }
      });
  }

  return (
    <>
      <form className="login-form" onSubmit={onSubmit}>
        <h2>Signup</h2>
        <input type="email" name="email" placeholder="E-Mail" required />
        <input type="password" name="password" placeholder="Passwort" required />
        <p className="error">{error}</p>
        <button>Registrieren</button>
      </form>
    </>
  );
}

export default Signup;
