import { useState, useContext, useEffect } from "react";
import AuthContext from "../contexts/AuthProvider";
import axios from "../api/axios";
import { useNavigate } from "react-router-dom";
import Add from "../components/Add";

function NewRecipe() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);

  const [ingredients, setIngredients] = useState([]);
  const [nutritionalValues, setNutritionalValues] = useState([]);
  const [ingredientsData, setIngredientsData] = useState({});
  const [nutritionalValuesData, setNutritionalValuesData] = useState({});

  useEffect(() => {
    if (!auth.email) {
      navigate("/login", { state: "new-recipe" });
    }

    async function fetchCategories() {
      await axios
        .get("/cookbook/categories", { withCredentials: true })
        .then((res) => {
          setCategories(res.data);
        })
        .catch(() => {});
    }
    fetchCategories();
  }, [navigate, auth]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const foundCategoryIDs = [];
    if (e.target["category"].value) {
      const inputCategories = e.target["category"].value.toLowerCase().trim().split(",");

      for (const category of inputCategories) {
        const foundCategory = categories.find((cat) => cat.name.toLowerCase() === category);
        if (foundCategory) {
          console.log("Found:", foundCategory);
          foundCategoryIDs.push(foundCategory._id);
        }
      }
    }

    const document = {
      name: e.target["name"].value,
      ingredients: ingredients,
      portion: e.target["portion"].value,
      nutritionalValues: nutritionalValues,
      directions: e.target["directions"].value,
      tags: e.target["tags"].value.split(","),
      href: e.target["href"].value,
      time: e.target["time"].value,
      categories: foundCategoryIDs,
    };

    await axios
      .post("/cookbook/recipe", document, { withCredentials: true })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        if (err.request.status === 422) {
          setError("Fehlerhafte Daten. Alle Felder müssen ausgefüllt sein.");
        } else if (err.request) {
          setError(
            "Verbindungsfehler. Überprüfe die Internetverbindung oder versuche es später erneut."
          );
        } else {
          setError(err.toString());
        }
      });
  };

  function changeIngredients(e) {
    const { name, value } = e.target;
    setIngredientsData((prev) => ({ ...prev, [name]: value }));
  }

  function submitIngredients() {
    setIngredients((prev) => {
      return [...prev, ingredientsData];
    });
    setIngredientsData({});
  }

  function removeIngredient(index) {
    const updatedIngredients = ingredients.splice(index, 1);
    setIngredientsData(updatedIngredients);
  }

  function changeNutritionalValues(e) {
    const { name, value } = e.target;
    setNutritionalValuesData((prev) => ({ ...prev, [name]: value }));
  }

  function submitNutritionalValues() {
    setNutritionalValues((prev) => {
      return [...prev, nutritionalValuesData];
    });
    setNutritionalValuesData({});
  }

  function removeNutritionalValue(index) {
    const updatedNutritionalValues = nutritionalValues.splice(index, 1);
    setNutritionalValuesData(updatedNutritionalValues);
  }

  return (
    <>
      <h1>Neues Rezept</h1>
      <div className="personal-container">
        <div className="catalog-filters">
          <div className="catalog-filter">
            {loading ? (
              <div className="loading">
                {error ? (
                  <div className="loading-content">
                    <p className="error">{error}</p>
                    <button
                      className="btn-primary"
                      onClick={() => {
                        setError("");
                        setLoading(false);
                      }}
                    >
                      Daten anpassen
                    </button>
                  </div>
                ) : (
                  <span className="loader"></span>
                )}
              </div>
            ) : (
              <Add
                handleSubmit={handleSubmit}
                ingredients={ingredients}
                ingredientsData={ingredientsData}
                changeIngredients={changeIngredients}
                submitIngredients={submitIngredients}
                removeIngredient={removeIngredient}
                nutritionalValues={nutritionalValues}
                nutritionalValuesData={nutritionalValuesData}
                changeNutritionalValues={changeNutritionalValues}
                submitNutritionalValues={submitNutritionalValues}
                removeNutritionalValue={removeNutritionalValue}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NewRecipe;
