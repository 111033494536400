import { useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../contexts/AuthProvider";
import "./NavBar.css";

function NavBar() {
  const { auth } = useContext(AuthContext);

  return (
    <nav>
      <ul>
        <li className="logo">
          <Link to="/">LET HIM COOK🔥- Das Kochbuch</Link>
        </li>
      </ul>
      <ul>
        {auth.email ? (
          <li className="login-dropdown">
            <div className="profile">
              <p>{auth.email}</p>
              <i className="fa-solid fa-user"></i>
            </div>
            <div className="nav-dropdown">
              <Link to="/personal">
                <i className="fa-solid fa-grip"></i>
                <p>Deine Rezepte</p>
              </Link>
              <Link to="/favourite">
                <i className="fa-solid fa-bookmark"></i>
                <p>Gemerkte Rezepte</p>
              </Link>
              <Link to="/new-recipe">
                <i className="fa-solid fa-square-plus"></i>
                <p>Neues Rezept</p>
              </Link>
              <Link to="/new-category">
                <i className="fa-solid fa-folder-plus"></i>
                <p>Neue Kategorie</p>
              </Link>
              <Link to="/logout">
                <i className="fa-solid fa-right-from-bracket"></i>
                <p>Logout</p>
              </Link>
            </div>
          </li>
        ) : (
          <li>
            <button>
              <Link to="/login">Login</Link>
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default NavBar;
