import { useState, useContext, useEffect } from "react";
import AuthContext from "../contexts/AuthProvider";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchRecipes, addFavourite, removeFavourite, search } from "../features/recipeSlice";
import Filter from "../components/Filter";
import Recipe from "../components/Recipe";

function Home() {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.recipes.recipes);
  const loading = useSelector((state) => state.recipes.loading);
  const error = useSelector((state) => state.recipes.error);
  const { auth } = useContext(AuthContext);

  const [filters, setFilters] = useState([]);

  useEffect(() => {
    dispatch(fetchRecipes({ auth, filters }));
  }, [dispatch, auth, filters]);

  function handleAddFavourite(recipeId) {
    dispatch(addFavourite(recipeId));
  }

  function handleRemoveFavourite(recipeId) {
    dispatch(removeFavourite(recipeId));
  }

  function handleSearch(query) {
    dispatch(search(query));
  }

  return (
    <>
      <h1>Kochbuch</h1>
      <div className="catalog-container">
        <div className="catalog">
          {loading ? (
            <div className="loading">
              {error ? <p className="error">{error}</p> : <span className="loader"></span>}
            </div>
          ) : (
            <>
              <h2>
                Katalog - <b>{items.length}</b> {items.length === 1 ? "Rezept" : "Rezepte"}
              </h2>
              <div className="catalog-items-container">
                {items.length <= 0 && (
                  <p className="info">
                    Du kannst
                    <Link to="/personal">
                      <b> hier </b>
                    </Link>
                    Rezepte erstellen.
                  </p>
                )}
                <div className="catalog-items">
                  {items?.map((recipe, index) => {
                    return (
                      <Recipe
                        key={recipe._id}
                        {...recipe}
                        index={index}
                        showFavourite={auth.email}
                        addFavourite={handleAddFavourite}
                        removeFavourite={handleRemoveFavourite}
                        isFavourite={recipe.saved}
                      />
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
        <Filter
          tags={items.reduce((combinedTags, recipe) => {
            return combinedTags.concat(recipe.tags);
          }, [])}
          filters={filters}
          setFilters={setFilters}
          handleSearch={handleSearch}
        />
      </div>
    </>
  );
}

export default Home;
