import { useEffect, useState, useContext } from "react";
import axios from "../api/axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import Comment from "../components/Comment";
import AuthContext from "../contexts/AuthProvider";

function RecipePage() {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState({});
  const { id } = useParams();
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate()
  const [commentText, setCommentText] = useState("");
  const [commentRating, setCommentRating] = useState("");

  useEffect(() => {
    async function fetchItems() {
      const res = await axios.get(`/cookbook/recipe/${id}`);
      setContent(res.data);
      setLoading(false);
    }
    setLoading(true);
    fetchItems();
  }, [id]);

  async function sendComment(e) {
    e.preventDefault();

    let endpoint = "/cookbook/comment/recipe";
    let data = { comment: commentText.trim(), recipe: id };

    if (commentRating > 0) {
      if (commentText.trim() === "") {
        endpoint = "/cookbook/rate/recipe";
        data = { rating: commentRating, recipe: id };
      } else {
        endpoint = "/cookbook/commentrate/recipe";
        data = { rating: commentRating, comment: commentText.trim(), recipe: id };
      }
    }

    axios.put(endpoint, data, { withCredentials: true }).then(() => {
      setCommentText("");
      setCommentRating("");
      navigate(0);
    });
  }

  return (
    <>
      {loading ? (
        <div className="loading">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className="recipe-container">
            <div className="info">
              <h1>{content.name}</h1>
              <div>
                <p>
                  {content.rating.length > 0 ? (
                    <>
                      Bewertung: {average(content.rating)} / 5 <i className="fa-solid fa-star"></i>
                    </>
                  ) : (
                    "Noch keine Bewertungen"
                  )}{" "}
                </p>
                <p>
                  Tags:{" "}
                  {content.tags.map((tag, index) => {
                    return (
                      <span key={index}>
                        <Link to={`/?t=${tag.toLowerCase()}`}>
                          <i>{tag}</i>
                        </Link>
                        {index + 1 < content.tags.length ? ", " : ""}
                      </span>
                    );
                  })}
                </p>
                <p>Vorbereitungsdauer: {content.time} Minuten</p>
                <p>Portion: {content.portion}</p>
              </div>
            </div>
            <div className="recipe-content">
              <div>
                <img src={content.href} alt="Preview" />
              </div>
              <div>
                <h2>Zutaten</h2>
                <ul>
                  {content.ingredients.map((ingredient, index) => {
                    return (
                      <li key={index}>
                        <p>
                          {ingredient.ingredient} - {ingredient.amount} {ingredient.unit}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="directions">
                <h2>Zubereitung:</h2>
                {content.directions.split("\\n").map((direction, index) => {
                  return <p key={index}>{direction}</p>;
                })}
              </div>
            </div>
          </div>
          <div className="comment-container">
            <h2>
              <b>{content.comments.length}</b>{" "}
              {content.comments.length === 1 ? "Kommentar" : "Kommentare"}
            </h2>
            <div>
              {auth.email ? (
                <form className="add-comment" onSubmit={sendComment}>
                  <input
                    type="text"
                    name="comment"
                    placeholder="Dein Kommentar"
                    value={commentText}
                    onInput={(e) => setCommentText(e.target.value)}
                  />
                  <input
                    type="number"
                    max={5}
                    min={1}
                    name="rating"
                    placeholder="Bewertung (Optional)"
                    value={commentRating}
                    onInput={(e) => setCommentRating(e.target.value)}
                  />
                  <button className="btn-primary">Senden</button>
                </form>
              ) : (
                <p>Du musst angemeldet sein um Kommentare und Bewertungen schreiben zu können.</p>
              )}
              <div className="comments">
                {content.comments.map((comment, index) => {
                  return <Comment key={index} {...comment} />;
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

function average(arr) {
  let sum = 0;
  for (const num of arr) {
    sum += parseInt(num);
  }
  return (sum / arr.length).toFixed(2);
}

export default RecipePage;
