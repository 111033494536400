import "./Add.css";

function Add({
  handleSubmit,
  ingredients,
  ingredientsData,
  changeIngredients,
  submitIngredients,
  removeIngredient,
  nutritionalValues,
  nutritionalValuesData,
  changeNutritionalValues,
  submitNutritionalValues,
  removeNutritionalValue,
}) {
  return (
    <form className="add-form" onSubmit={handleSubmit}>
      <div>
        <input type="text" placeholder="Name" name="name" />
      </div>
      <div>
        <label>Zutaten</label>
        <div className="multi-container">
          <div className="multi-items-container">
            {ingredients?.map((ingredient, index) => {
              return (
                <div key={index}>
                  <input type="text" value={ingredient.ingredient} readOnly />
                  <input type="number" value={ingredient.amount} readOnly />
                  <input type="text" value={ingredient.unit} readOnly />
                  <button type="button" onClick={() => removeIngredient(index)}>
                    Entfernen
                  </button>
                </div>
              );
            })}
          </div>
          <div>
            <input
              type="text"
              placeholder="Zutat"
              value={ingredientsData.ingredient || ""}
              name="ingredient"
              onChange={changeIngredients}
            />
            <input
              type="number"
              placeholder="Menge"
              value={ingredientsData.amount || ""}
              name="amount"
              onChange={changeIngredients}
            />
            <input
              type="text"
              placeholder="Einheit"
              value={ingredientsData.unit || ""}
              name="unit"
              onChange={changeIngredients}
            />
            <button type="button" onClick={submitIngredients}>
              Zutat hinzufügen
            </button>
          </div>
        </div>
      </div>
      <div>
        <input type="text" placeholder="Portion" name="portion" />
      </div>
      <div>
        <label>Ernährungswerte</label>
        <div className="multi-container">
          <div className="multi-items-container">
            {nutritionalValues?.map((nutritionalValue, index) => {
              return (
                <div key={index}>
                  <input type="text" value={nutritionalValue.kcal} readOnly />
                  <input type="text" value={nutritionalValue.protein} readOnly />
                  <input type="text" value={nutritionalValue.fat} readOnly />
                  <input type="text" value={nutritionalValue.carbohydrates} readOnly />
                  <button type="button" onClick={() => removeNutritionalValue(index)}>
                    Entfernen
                  </button>
                </div>
              );
            })}
          </div>
          <div>
            <input
              type="text"
              placeholder="kcal"
              value={nutritionalValuesData.kcal || ""}
              name="kcal"
              onChange={changeNutritionalValues}
            />
            <input
              type="text"
              placeholder="Proteine"
              value={nutritionalValuesData.protein || ""}
              name="protein"
              onChange={changeNutritionalValues}
            />
            <input
              type="text"
              placeholder="Fett"
              value={nutritionalValuesData.fat || ""}
              name="fat"
              onChange={changeNutritionalValues}
            />
            <input
              type="text"
              placeholder="Kohlenhydrate"
              value={nutritionalValuesData.carbohydrates || ""}
              name="carbohydrates"
              onChange={changeNutritionalValues}
            />
            <button type="button" onClick={submitNutritionalValues}>
              Wert hinzufügen
            </button>
          </div>
        </div>
      </div>
      <div>
        <textarea placeholder="Anweisungen" name="directions" cols="100" rows="10" />
      </div>
      <div>
        <input type="text" placeholder="Tags (kommagetrennt)" name="tags" />
      </div>
      <div>
        <input type="text" placeholder="Bild (URL)" name="href" />
      </div>
      <div>
        <input type="number" placeholder="Zubereitungsdauer (Minuten)" name="time" />
      </div>
      <div>
        <input type="text" placeholder="Kategorien (kommagetrennt)" name="category" />
      </div>
      <button>Rezept hochladen</button>
    </form>
  );
}

export default Add;
