import { useState, useContext, useEffect } from "react";
import AuthContext from "../contexts/AuthProvider";
import { useLocation, useNavigate, Link } from "react-router-dom";
import axios from "../api/axios";

function Login() {
  const location = useLocation();
  const { auth, replaceAuth } = useContext(AuthContext);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.email) {
      navigate("/" + (location.state ? location.state : "personal"));
    }
  }, [auth, navigate, location.state]);

  async function onSubmit(e) {
    e.preventDefault();
    setError("");

    const email = e.target.email.value.trim();
    const password = e.target.password.value.trim();
    console.log(email, password);

    if (email.length <= 0 || password.length <= 0) {
      setError("Die Felder dürfen nicht leer sein!");
      return;
    }

    await axios
      .post("/login", { email, password }, { withCredentials: true })
      .then((res) => {
        console.log("Response", res?.data);
        replaceAuth({ email: email });
        navigate("/" + (location.state ? location.state : ""));
      })
      .catch((err) => {
        if (err.request.status === 401) {
          setError("Falsche Anmeldedaten.");
        } else if (err.request) {
          setError(
            "Verbindungsfehler. Überprüfe die Internetverbindung oder versuche es später erneut."
          );
        } else {
          setError(err.toString());
        }
      });
  }

  return (
    <>
      <form className="login-form" onSubmit={onSubmit}>
        <h2>Login</h2>
        <input
          type="email"
          name="email"
          placeholder="E-Mail"
          required
          onChange={() => setError("")}
        />
        <input
          type="password"
          name="password"
          placeholder="Passwort"
          required
          onChange={() => setError("")}
        />
        <p className="error">{error}</p>
        <button>Login</button>
        <p>
          Kein Konto?{" "}
          <Link to="/signup" state={location.state} className="signup">
            Registrieren.
          </Link>
        </p>
      </form>
    </>
  );
}

export default Login;
