import { useState, useContext, useEffect } from "react";
import AuthContext from "../contexts/AuthProvider";
import axios from "../api/axios";
import { useNavigate } from "react-router-dom";

function NewCategory() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (!auth.email) {
      navigate("/login", { state: "new-category" });
    }

    async function fetchCategories() {
      await axios
        .get("/cookbook/categories", { withCredentials: true })
        .then((res) => {
          setCategories(res.data);
        })
        .catch(() => {});
    }
    fetchCategories();
  }, [navigate, auth]);

  async function addCategory(e) {
    e.preventDefault();
    setLoading(true);

    const category = e.target["category"].value.trim().toLowerCase();
    const foundCategory = categories.find((cat) => cat.name.toLowerCase() === category);
    if (foundCategory) {
      setError("Diese Kategorie existiert bereits.");
      return;
    }

    await axios
      .post("/cookbook/category", { name: category }, { withCredentials: true })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        if (err.request.status === 422) {
          setError("Fehlerhafte Daten. Alle Felder müssen ausgefüllt sein.");
        } else if (err.request) {
          setError(
            "Verbindungsfehler. Überprüfe die Internetverbindung oder versuche es später erneut."
          );
        } else {
          setError(err.toString());
        }
      });
  }

  return (
    <>
      <h1>Neue Kategorie</h1>
      <div className="personal-container">
        <div className="catalog-filters">
          <div className="catalog-filter">
            {loading ? (
              <div className="loading">
                {error ? (
                  <div className="loading-content">
                    <p className="error">{error}</p>
                    <button
                      className="btn-primary"
                      onClick={() => {
                        setError("");
                        setLoading(false);
                      }}
                    >
                      Eingabe anpassen
                    </button>
                  </div>
                ) : (
                  <span className="loader"></span>
                )}
              </div>
            ) : (
              <form className="add-form" onSubmit={addCategory}>
                <input type="text" name="category" placeholder="Kategorie" required />
                <button>Kategorie hinzufügen</button>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NewCategory;
