import { createReducer, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "../api/axios";

export const fetchRecipes = createAsyncThunk("recipes/fetchRecipes", async (obj) => {
  let currentItems = [];
  const { auth, filters } = obj;

  if (filters.length <= 0) {
    await axios
      .get("/cookbook/recipes")
      .then((res) => {
        currentItems = res.data;
      })
      .catch((err) => {
        if (err.request) {
          throw new Error(
            "Verbindungsfehler. Überprüfe die Internetverbindung oder versuche es später erneut."
          );
        } else {
          throw new Error(err.message);
        }
      });
  } else if (filters.length > 0 && !!auth.email) {
    const newFilters = transformArray(filters);

    const params = new URL(axios.getUri() + "/cookbook/search");
    if (newFilters.t) {
      params.searchParams.append("tags", newFilters.t.join(","));
    }
    if (newFilters.c) {
      const filterIDs = [];
      for (const filter of filters) {
        filters.find(
          (f) => f.title === "c" && f.content === filter.content && filterIDs.push(f.id)
        );
      }
      params.searchParams.append("category", filterIDs.join(","));
    }

    await axios
      .get(params.href, {
        withCredentials: true,
      })
      .then((res) => {
        currentItems = res.data;
      })
      .catch((err) => {
        if (err.request) {
          throw new Error(
            "Verbindungsfehler. Überprüfe die Internetverbindung oder versuche es später erneut."
          );
        } else {
          throw new Error(err.message);
        }
      });
  }

  if (auth.email) {
    await axios.get("/cookbook/saved", { withCredentials: true }).then((res) => {
      for (const item of currentItems) {
        if (res.data.savedRecipes.find((i) => i._id === item._id)) {
          item.saved = true;
        }
      }
    });
  }
  return currentItems;
});

export const addFavourite = createAsyncThunk("recipes/addFavourite", async (recipeId) => {
  try {
    await axios.put("/cookbook/save/recipe", { recipe: recipeId }, { withCredentials: true });
    return recipeId;
  } catch (error) {
    throw new Error(error.message);
  }
});

export const removeFavourite = createAsyncThunk("recipes/removeFavourite", async (recipeId) => {
  try {
    await axios.put(
      "/cookbook/save/delete/recipe",
      { recipe: recipeId },
      { withCredentials: true }
    );
    return recipeId;
  } catch (error) {
    throw new Error(error.message);
  }
});

export const search = createAction("recipes/search");

const initialState = {
  recipes: [],
  originalRecipes: [],
  loading: false,
  error: null,
};

const recipeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchRecipes.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchRecipes.fulfilled, (state, action) => {
      state.loading = false;
      state.recipes = action.payload;
      state.originalRecipes = action.payload;
    })
    .addCase(fetchRecipes.rejected, (state, action) => {
      state.loading = true;
      state.error = action.error.message;
    })
    .addCase(addFavourite.fulfilled, (state, action) => {
      const recipeId = action.payload;
      state.recipes = state.recipes.map((recipe) =>
        recipe._id === recipeId ? { ...recipe, saved: true } : recipe
      );
    })
    .addCase(removeFavourite.fulfilled, (state, action) => {
      const recipeId = action.payload;
      state.recipes = state.recipes.map((recipe) =>
        recipe._id === recipeId ? { ...recipe, saved: false } : recipe
      );
    })
    .addCase(search, (state, action) => {
      const query = action.payload;
      state.recipes = state.originalRecipes.filter((recipe) =>
        recipe.name.toLowerCase().includes(query.toLowerCase())
      );
    });
});

function transformArray(array) {
  const result = {};

  array.forEach((obj) => {
    const { title, content } = obj;

    if (!result[title]) {
      result[title] = [content];
    } else {
      result[title].push(content);
    }
  });
  return result;
}

export default recipeReducer;
