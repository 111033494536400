import { useState, useContext, useEffect } from "react";
import AuthContext from "../contexts/AuthProvider";
import axios from "../api/axios";
import { Link, useNavigate } from "react-router-dom";
import Recipe from "../components/Recipe";

function Favourite() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.email) {
      navigate("/login", { state: "favourite" });
    }

    async function fetchItems() {
      await axios
        .get("/cookbook/saved", { withCredentials: true })
        .then((res) => {
          setItems(res.data.savedRecipes);
          setLoading(false);
        })
        .catch((err) => {
          setError(err.toString());
        });
    }
    setLoading(true);
    fetchItems();
  }, [navigate, auth]);

  async function removeFavourite(id) {
    await axios
      .put("/cookbook/save/delete/recipe", { recipe: id }, { withCredentials: true })
      .then((res) => {
        console.log("Response", res?.data);
      });

    setItems((prev) => {
      return prev.filter((item) => item._id !== id);
    });
  }

  return (
    <>
      <h1>Favoriten</h1>
      <div className="personal-container">
        <div className="catalog">
          {loading ? (
            <div className="loading">
              {error ? <p className="error">{error}</p> : <span className="loader"></span>}
            </div>
          ) : (
            <>
              <h2>
                <b>{items.length}</b> {items.length === 1 ? "gemerktes Rezept" : "gemerkte Rezepte"}
              </h2>
              <div className="catalog-items-container">
                {items.length <= 0 && (
                  <p className="info">
                    Du kannst
                    <Link to="/">
                      <b> hier </b>
                    </Link>
                    Rezepte speichern.
                  </p>
                )}
                <div className="catalog-items">
                  {items?.map((recipe, index) => {
                    return (
                      <Recipe
                        key={recipe._id}
                        {...recipe}
                        index={index}
                        showFavourite
                        isFavourite
                        removeFavourite={removeFavourite}
                      />
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Favourite;
