import { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "../api/axios";
import AuthContext from "../contexts/AuthProvider";
import "./Filter.css";

// Full: display name in DOM, Short: URL search parameter
const availableFilters = [
  {
    full: "Kategorie",
    short: "c",
  },
  {
    full: "Tag",
    short: "t",
  },
];

function Filter({ tags, filters, setFilters, handleSearch }) {
  const { auth } = useContext(AuthContext);

  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterSelected, setFilterSelected] = useState(0);

  const [search, setSearch] = useState("");
  const [params, setParams] = useSearchParams({});

  const [categories, setCategories] = useState([]);
  const [inputSelected, setInputSelected] = useState(0);

  // Take URL search params and build filter buttons
  useEffect(() => {
    // Skip if already loaded
    if (filtersLoaded) {
      return;
    }
    setFiltersLoaded(true);

    // Transform search params into usable array
    const filterArr = [];
    for (const entry of params.entries()) {
      if (entry[0] === "q") {
        setSearch(entry[1]);
        continue;
      }

      if (!availableFilters.find((f) => f.short === entry[0])) {
        continue;
      }

      for (const content of entry[1].split(",")) {
        filterArr.push({
          title: entry[0],
          content: content,
        });
      }
    }
    setFilters(filterArr);

    async function fetchCategories() {
      await axios
        .get("/cookbook/categories", { withCredentials: true })
        .then((res) => {
          setCategories(res.data);
        })
        .catch(() => {});
    }
    fetchCategories();
  }, [params, filtersLoaded, setFilters, search, handleSearch]);

  function addFilter() {
    setFilterVisible(false);
    let filter = {
      title: availableFilters[filterSelected].short,
    };

    if (filter.title === "c") {
      filter.content = categories[inputSelected].name;
      filter.id = categories[inputSelected]._id;
    } else {
      filter.content = tags[inputSelected];
    }

    const newFilters = [...filters, filter];

    setFilters(newFilters);
    setInputSelected(0);

    setSearchParams(newFilters, search);
  }

  function removeFilter(index) {
    const newFilters = [...filters];
    newFilters.splice(index, 1);
    setFilters(newFilters);
    setSearchParams(newFilters, search);
  }

  function changeSearch(e) {
    handleSearch(e.target.value);
    setSearch(e.target.value);
    setSearchParams(filters, e.target.value);
  }

  function setSearchParams(filters, search) {
    const arr = [...filters];
    if (search) {
      arr.push({
        title: "q",
        content: search,
      });
    }
    setParams(arrayToObject(arr));
  }

  return (
    <div className="catalog-filters">
      <div className="catalog-filter">
        <div className="filter-container">
          <h2>Suche</h2>
          <div className="search-input">
            <i className="fa-solid fa-magnifying-glass"></i>
            <input
              type="text"
              name="search"
              placeholder="Suchen ..."
              value={search}
              onInput={changeSearch}
            />
          </div>
        </div>
      </div>

      <div className="catalog-filter">
        <div className="filter-container">
          <h2>Filter</h2>
          {auth.email ? (
            <>
              <div className="selected-filters">
                {filters?.map((filter, index) => {
                  return (
                    <button className="filter" key={index} onClick={() => removeFilter(index)}>
                      <div>
                        <p>{availableFilters.find((f) => f.short === filter.title).full}</p>
                        <p className="filter-content">{filter.content}</p>
                      </div>
                      <i className="fa-solid fa-minus"></i>
                    </button>
                  );
                })}
              </div>
              <div className={`filter-dropdown ${filterVisible && "open"}`}>
                <div className="dropdown-visible" onClick={() => setFilterVisible((prev) => !prev)}>
                  <p>Filter hinzufügen</p>
                  <i className="fa-solid fa-plus dropdown-rotate"></i>
                </div>
                <div className="dropdown_content">
                  <select name="filter" onChange={(e) => setFilterSelected(e.target.value)}>
                    {availableFilters?.map((filter, index) => {
                      return (
                        <option key={index} value={index}>
                          {filter.full}
                        </option>
                      );
                    })}
                  </select>
                  <select name="input" onChange={(e) => setInputSelected(e.target.value)}>
                    {availableFilters[filterSelected].short === "c"
                      ? categories.map((cat, index) => (
                          <option key={index} value={index}>
                            {cat.name}
                          </option>
                        ))
                      : tags.map((tag, index) => (
                          <option key={index} value={index}>
                            {tag}
                          </option>
                        ))}
                  </select>
                  <button className="add-btn" id="dialog-filter" onClick={addFilter}>
                    <p>Hinzufügen</p>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <p>Du musst angemeldet sein um Filter nutzen zu können.</p>
          )}
        </div>
      </div>
    </div>
  );
}

// Transform an array into an object that can be used for URL search params
function arrayToObject(arr) {
  const obj = {};
  arr.forEach(({ title, content }) => {
    obj[title] = obj[title] ? `${obj[title]},${content}` : content;
  });
  return obj;
}

export default Filter;
