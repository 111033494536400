import "./Tooltip.css";

function Tooltip(props) {
  return (
    <div className="tooltip-container">
      <p className="tooltip">{props.content}</p>
      {props.children}
    </div>
  );
}

export default Tooltip;
