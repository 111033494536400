import { createContext, useState, useEffect } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});

  useEffect(() => {
    const localAuth = JSON.parse(localStorage.getItem("auth"));

    if (localAuth) {
      setAuth(localAuth);
    } else {
      localStorage.setItem("auth", JSON.stringify({}));
    }
  }, []);

  function replaceAuth(authObject) {
    localStorage.setItem("auth", JSON.stringify(authObject));
    setAuth(authObject);
  }

  return (
    <AuthContext.Provider value={{ auth, replaceAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;