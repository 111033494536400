import { Link } from "react-router-dom";
import Tooltip from "./Tooltip";
import "./Recipe.css";

function Recipe(props) {
  return (
    <div className="recipe">
      <div className="recipe-header">
        <h3>
          {/* [{props.index + 1}]  */}
          {props.name}
        </h3>
        <div className="recipe-icons">
          <Tooltip content="Rezept ansehen">
            <Link to={`/recipe/${props._id}`}>
              <i className="fa-solid fa-arrow-up-right-from-square"></i>
            </Link>
          </Tooltip>

          {props.showFavourite && (
            <>
              {props.isFavourite ? (
                <Tooltip content="Favorit entfernen">
                  <button onClick={() => props.removeFavourite(props._id)}>
                    <i className="fa-solid fa-bookmark"></i>
                  </button>
                </Tooltip>
              ) : (
                <Tooltip content="Favorit hinzufügen">
                  <button onClick={() => props.addFavourite(props._id)}>
                    <i className="fa-regular fa-bookmark"></i>
                  </button>
                </Tooltip>
              )}
            </>
          )}

          {props.isRemovable && (
            <Tooltip content="Rezept löschen">
              <button onClick={() => props.removeRecipe(props._id)}>
                <i className="fa-solid fa-trash"></i>
              </button>
            </Tooltip>
          )}
        </div>
      </div>
      <div className="recipe-body">
        <div className="recipe-info">
          <p className="recipe-time">
            <i className="fa-solid fa-stopwatch"></i>
            {props.time} Minuten
          </p>
          <p>
            {props.ingredients.length} {props.ingredients.length === 1 ? "Zutat" : "Zutaten"}
          </p>
        </div>
        <p>
          {props.tags.map((tag, index) => {
            return (
              <span key={index}>
                <a href={`/?t=${tag.toLowerCase()}`}>
                  <i>{tag}</i>
                </a>
                {index + 1 < props.tags.length ? ", " : ""}
              </span>
            );
          })}
        </p>
        <img src={props.href} alt="Preview" />
      </div>
    </div>
  );
}

export default Recipe;
