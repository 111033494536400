import { useState, useContext, useEffect } from "react";
import AuthContext from "../contexts/AuthProvider";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios";

function Logout() {
  const { replaceAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  useEffect(() => {
    async function logout() {
      await axios
        .post("/logout", {}, { withCredentials: true })
        .then((res) => {
          console.log("Response", res?.data);
          replaceAuth({});
          navigate("/", { replace: true });
        })
        .catch((err) => {
          if (err.request) {
            setError(
              "Verbindungsfehler. Überprüfe die Internetverbindung oder versuche es später erneut."
            );
          } else {
            setError(err.toString());
          }
        });
    }
    logout();
  }, [navigate, replaceAuth]);

  return (
    <>
      <h2>Now Loggin Out ...</h2>
      <p className="error">{error}</p>
    </>
  );
}

export default Logout;
