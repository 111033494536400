import React from "react";
import { Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import Personal from "./pages/Personal";
import NewRecipe from "./pages/NewRecipe";
import NewCategory from "./pages/NewCategory";
import Favourite from "./pages/Favourite";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import RecipePage from "./pages/RecipePage";

function App() {
  return (
    <div className="App">
      <NavBar />
      <div className="page">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/recipe/:id" element={<RecipePage />} />
          <Route path="/personal" element={<Personal />} />
          <Route path="/new-recipe" element={<NewRecipe />} />
          <Route path="/new-category" element={<NewCategory />} />
          <Route path="/favourite" element={<Favourite />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
