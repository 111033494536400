import { useState, useContext, useEffect } from "react";
import AuthContext from "../contexts/AuthProvider";
import axios from "../api/axios";
import { Link, useNavigate } from "react-router-dom";
import Recipe from "../components/Recipe";

function Personal() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.email) {
      navigate("/login", { state: "personal" });
    }

    async function fetchItems() {
      await axios
        .get("/cookbook/user/recipe", { withCredentials: true })
        .then((res) => {
          setItems(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setError(err.toString());
        });
    }
    setLoading(true);
    fetchItems();
  }, [navigate, auth]);

  async function removeRecipe(id) {
    await axios.delete("/cookbook/delete/" + id, { withCredentials: true }).then((res) => {
      console.log("Response", res?.data);
    });

    setItems((prev) => {
      return prev.filter((item) => item._id !== id);
    });
  }

  return (
    <>
      <h1>Deine Rezepte</h1>
      <div className="personal-container">
        <div className="catalog">
          {loading ? (
            <div className="loading">
              {error ? <p className="error">{error}</p> : <span className="loader"></span>}
            </div>
          ) : (
            <>
              <h2>
                <b>{items.length}</b> {items.length === 1 ? "eigenes Rezept" : "eigene Rezepte"}
              </h2>
              <div className="catalog-items-container">
                {items.length <= 0 && (
                  <p className="info">
                    Du kannst
                    <Link to="/new">
                      <b> hier </b>
                    </Link>
                    Rezepte erstellen.
                  </p>
                )}
                <div className="catalog-items">
                  {items?.map((recipe, index) => {
                    return (
                      <Recipe
                        key={recipe._id}
                        {...recipe}
                        index={index}
                        isRemovable
                        removeRecipe={removeRecipe}
                      />
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Personal;
